html, body{
  height: 100%;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.justify-content-end{
  justify-content: end;
}

 /* found, arrived */
.aps-done{
  background-color:#BDC4FE;
}

/* found, not arrived */
.aps-safe{ 
  background-color:#C5FEBD;
}

/* not found, arrived */
.aps-danger{ 
  background-color:#FEBDC5;
}

.nav-link.active{
  border-bottom: 2px #fff solid;
}

.react-datepicker__input-container, .react-datepicker-wrapper{
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
}

.react-datepicker__input-container > input {
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}